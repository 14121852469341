import React from 'react'
import { Link } from 'react-router-dom'
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ReactTelInput from 'react-telephone-input'
import 'react-telephone-input/lib/withStyles'
import blue from "@material-ui/core/colors/blue";
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
    },
    aLink: {
        color: blue[500],
    },
});

class SignupForm extends React.Component {
  constructor() {
    super()
    this.state = {
      code: '',
      btnValue: '发送',
      telNumber: '',
      nationCode: 86
    }
    let int = 0
  }
  codeChange= (e) => {
    this.setState({
      code: e.target.value
    })
  }
  handleSubmit = (e) => {
      if(!this.props.username) {
          this.props.messageWarning('请输入用户名！');
          return
      }
      if(!this.props.email) {
          this.props.messageWarning('请输入邮箱！');
          return
      }
      if(!this.props.password) {
          this.props.messageWarning('请输入密码！');
          return
      }
      if(!this.props.vcode) {
          this.props.messageWarning('请输入验证码！');
          return
      }
      const noChinese = /^[^\u4e00-\u9fa5]+$/
      const noSymbol = /^\w{3,20}$/
      if(!noChinese.test(this.props.username)) {
          this.props.messageWarning('用户名中不能包含中文！')
          return
      }
      if(!noSymbol.test(this.props.username)) {
          this.props.messageWarning('用户名中不能包含特殊符号！')
          return
      }

      if(this.props.password != this.props.confirm_password) {
          this.props.messageWarning('两次输入的密码不同！')
          return
      }



        const {telNumber, nationCode} = this.state;
        this.props.onSubmit(telNumber, nationCode)

  }
  // checkSendBtn() {
  //   const myReg = /^[1][3,4,5,7,8][0-9]{9}$/
  //   if(myReg.test(this.props.form.getFieldValue('phone'))) {
  //     return false
  //   }
  //   return true
  // }
  sendVCode() {
    const {captchaID,captcha_answer} = this.props
    const {telNumber, nationCode, } = this.state
    // console.log(captchaID,captcha_answer)
    if (!captchaID || !captchaID.length) {
      this.props.messageError('请等待图形验证码')
      return
    }
    if ( !captcha_answer || !captcha_answer.length) {
      this.props.messageError('请输入图形验证码')
      return 
    }
    if(telNumber && telNumber.length) {
      const captcha = captcha_answer.toLocaleLowerCase() 
      this.props.onSendCode(telNumber, nationCode, captchaID, captcha)
    //   let time = 60
      this.int = setInterval(() => {this.props.setSendCodeDisabledTime(this.props.sendCodeDisabledTime-1)}, 1000)
    } else {
      this.props.messageError('请输入手机号码！')
    }
  }
  handleInputChange = (telNumber, selectedCountry) => {
    const reg = `+${selectedCountry.dialCode}`
    let number = telNumber.split(reg)[1]
    this.setState({
      telNumber: number,
      nationCode: selectedCountry.dialCode
    })
  }
  hasErrors() {
    let fieldsError = this.props.form.getFieldsError()
    return Object.keys(fieldsError).some(field => fieldsError[field])
  }

  checkName = (value) => {
    if(!value) {
      this.props.messageWarning('请输入用户名！')
    }
    const noChinese = /^[^\u4e00-\u9fa5]+$/
    const noSymbol = /^\w{3,20}$/
    if(!noChinese.test(value)) {
        this.props.messageWarning('用户名中不能包含中文！')
    }
    if(!noSymbol.test(value)) {
        this.props.messageWarning('用户名中不能包含特殊符号！')
    }
  }

  render() {
    // console.log(this.props.captchab64s)
      const {classes} = this.props;
    return (
        <div>
            <main className={classes.main}>
                <CssBaseline />
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        注册
                    </Typography>
                    <form className={classes.form}>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="username">用户名</InputLabel>
                            <Input id="username" name="username" autoComplete="username" autoFocus value={this.props.username || ""} onChange={this.props.onChange}/>
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="email">邮箱</InputLabel>
                            <Input id="email" name="email" autoComplete="email" autoFocus value={this.props.email || ""} onChange={this.props.onChange}/>
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="password">密码</InputLabel>
                            <Input name="password" type="password" id="password" autoComplete="current-password" value={this.props.password || ""}
                                   onChange={this.props.onChange}/>
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="confirm_password">确认密码</InputLabel>
                            <Input name="confirm_password" type="password" id="confirm_password" value={this.props.confirm_password || ""}
                                   onChange={this.props.onChange}/>
                        </FormControl>
                        <FormControl margin="normal" required >
                            <Grid container spacing={1}>
                                <Grid item xs={8}>
                                    <InputLabel htmlFor="captcha">图形验证码</InputLabel>
                                    <Input id="captcha_answer" name="captcha_answer" value={this.props.captcha_answer || ""} onChange={this.props.onChange}/>
                                </Grid>
                                <Grid item xs={4} style={{textAlign: "right"}}>
                                    <Button disabled={this.props.renewCaptchaLoading} onClick={() => this.props.onRenewCaptcha()} size="small">
                                        <img src={this.props.captchab64s} height={"40px"} width={"100px"}/>
                                    </Button>
                                </Grid>
                            </Grid>
                        </FormControl>
                        <FormControl margin="normal" required >
                            <Grid container spacing={16}>
                                <Grid item xs={6}>
                                    <ReactTelInput
                                        autoFormat={false}
                                        defaultCountry='cn'
                                        flagsImagePath='/flags.png'
                                        onChange={this.handleInputChange}
                                        preferredCountries={['cn', 'hk', 'mo', 'tw', 'us']}
                                    />
                                </Grid>
                                <Grid item xs={6} style={{textAlign: "right"}}>
                                    <Button disabled={this.props.sendCodeDisabledTime > 0} onClick={() => this.sendVCode()} color="primary" variant="contained" size="small">{this.props.sendCodeDisabledTime>0?`${this.props.sendCodeDisabledTime}S`:"发送"}</Button>
                                </Grid>
                            </Grid>
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="vcode">验证码</InputLabel>
                            <Input id="vcode" name="vcode" autoComplete="vcode" autoFocus value={this.props.vcode || ""} onChange={this.props.onChange}/>
                        </FormControl>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={this.handleSubmit}
                            className={classes.submit}
                        >
                            注册
                        </Button>
                        <Typography>
                            <Link to={{ pathname: '/login', search: this.props.query }} className={classes.aLink}>登录？</Link>
                        </Typography>

                    </form>
                </Paper>
            </main>
            {/*<Form onSubmit={this.handleSubmit}>
                <Form.Item>
                    <h1>注册</h1>
                </Form.Item>
                <Form.Item>
                    {this.props.form.getFieldDecorator('username', {
                        rules: [
                            { required: true, validator: this.checkName }
                        ]
                    })(
                        <Input prefix={<Icon type="user" />} placeholder="用户名" />
                    )}
                </Form.Item>
                <Form.Item>
                    {this.props.form.getFieldDecorator('email', {
                        rules: [
                            { required: true, message: '请输入邮箱！' },
                            { type: 'email' }
                        ]
                    })(
                        <Input prefix={<Icon type="mail" />} type="email" placeholder="邮箱" />
                    )}
                </Form.Item>
                <Form.Item>
                    {this.props.form.getFieldDecorator('password', {
                        rules: [
                            { required: true, message: '请输入密码！' },
                            { min: 6 }
                        ]
                    })(
                        <Input prefix={<Icon type="lock" />} type="password" placeholder="密码" />
                    )}
                </Form.Item>
                <Form.Item>
                    {this.props.form.getFieldDecorator('confirm_password', {
                        rules: [
                            { required: true, message: '请再次输入密码！' },
                            { validator: (rule, value, callback) => {
                                    if(value === this.props.form.getFieldValue('password')) { callback() } else { callback('两次输入密码不一致！') }
                                }}
                        ]
                    })(
                        <Input prefix={<Icon type="lock" />} type="password" placeholder="确认密码" />
                    )}
                </Form.Item>
                <Form.Item>
                    <Row>
                        <Col span={16}>
                            <ReactTelInput
                                autoFormat={false}
                                defaultCountry='cn'
                                flagsImagePath='/flags.png'
                                onChange={this.handleInputChange}
                                preferredCountries={['cn', 'hk', 'mo', 'tw', 'us']}
                            />
                        </Col>
                        <Col span={8} push={3}>
                            <Button disabled={this.state.btnDisabled} onClick={() => this.sendVCode()} type="primary">{this.state.btnValue}</Button>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item>
                    {this.props.form.getFieldDecorator('vcode', {
                        rules: [
                            { required: true, message: '请输入验证码！'},
                        ]
                    })(
                        <Input prefix={<Icon type="message" />} onChange={this.codeChange} placeholder="验证码" />
                    )}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={this.props.loading} disabled={this.hasErrors()} style={{ width: "100%" }}>注册</Button>
                </Form.Item>
                <Link to={{ pathname: '/login', search: this.props.query }}>登录？</Link>
            </Form>*/}
        </div>

    )
  }
  componentWillUnmount() {
    clearInterval(this.int)
  }
}

const option = {
  onFieldsChange(props, changedFields) {
    props.onChange(changedFields)
  },
  mapPropsToFields(props) {
    return {
      ...props
    }
  }
}

export default withStyles(styles)(SignupForm)
