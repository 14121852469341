import * as actions from './actions.js'
import reducer from './reducer.js'

function getArgs(queryString) {
    const urlParams = new URLSearchParams(decodeURIComponent(queryString));
    const params = {};
    for (const [key, value] of urlParams.entries()) {
        params[key] = value;
    }
    return params;
  }

export {actions, reducer, getArgs}