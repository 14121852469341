import React from 'react'
import { connect } from 'react-redux'
import { signup, sendCode, renewCaptcha, setSendCodeDisabledTime } from '../actions'
import SignupForm from './signupForm'
import {messageError, messageOk, messageWarning} from "../../utils/actions";
import { getArgs } from '../../utils';

class SignupBox extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.onFormChange = this.onFormChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.state = {
      formData: {},
      queryData: {}
    }
  }
  onFormChange(event) {
      this.setState({

          formData: {
              ...this.state.formData,
              [event.target.name]: event.target.value
          }
      })
  }
  onSubmit(phone, nationCode) {
    let temp = {...this.state.formData}
    temp.mobile = phone
    temp.country_code = nationCode
    this.props.onSubmit(temp, this.state.queryData)
  }
  // getArgs() {
  //   let args = {},
  //       query = this.props.location.search,
  //       search = decodeURIComponent(query.substring(1)),
  //       reg = /(?:([^&]+)=([^&]+))/g,
  //       match = reg.exec(search)
  //   while (match) {
  //     args[match[1]] = match[2]
  //     match = reg.exec(search)
  //   }
  //   return args
  // }
  onCheckCode(phone, code) {
    this.props.onCheckCode(phone, code, this.onSubmit)
  }
  render () {
    const formParam = {
      ...this.props,
      messageError: this.props.messageError,
      messageWarning: this.props.messageWarning,
      onSendCode: (phone, code, captcha_id, captcha_answer) => this.props.onSendCode(phone, code, captcha_id, captcha_answer),
      onSubmit: (phone, nationCode) => this.onSubmit(phone, nationCode),
      onRenewCaptcha: ()=> this.props.onRenewCaptcha(),
      onChange: fields => this.onFormChange(fields),
      loading: this.props.loading,
      query: this.props.location.search,
      ...this.state.formData,
    }
    return (
      <SignupForm {...formParam}/>
    )
  }
  componentDidMount() {
    this.props.onRenewCaptcha();
    let args = getArgs(this.props.location.search)
    this.setState({
      formData: {
        username: args.username
      },
      queryData: {
        source: args.source || 'nms',
        inviter: args.inviter,
        logintype: args.logintype,
        usermine_inviter: args.usermine_inviter,
        redirect_url: args.redirect_url || args.redirect_uri,
        client_id: args.client_id,
        state: args.state,
        scope: args.scope,
        response_type: args.response_type,
      }
    });
      // console.log('inviter', args.inviter);
      // console.log('usermine_inviter', args.usermine_inviter);
      // console.log('logintype', args.logintype);

      if (args.usermine_inviter) {
        localStorage.setItem("inviter_timestamp", new Date().getTime())
        localStorage.setItem("usermine_inviter", args.usermine_inviter)
      }
      if (args.inviter) {
        localStorage.setItem("inviter",args.inviter)
      }
      
      // localStorage.setItem("logintype", args.logintype?args.logintype:"")
  }
}

const mapStateToProps = (state) => {
  return {
    loading: (state.auth.signupStatus === 'loading') ? true : false,
    renewCaptchaLoading: (state.auth.renewCaptchaStatus === 'loading') ? true : false,
    captchaID: state.auth.captchaID,
    captchab64s: state.auth.captchab64s,
    sendCodeDisabledTime: state.auth.sendCodeDisabledTime,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSendCodeDisabledTime:(data)=>{
      
      dispatch(setSendCodeDisabledTime(data))
    },
    onRenewCaptcha: () => {
      dispatch(renewCaptcha({
        success: (result) => {
        },
        error: (error) => {
            dispatch(messageError("获取图形验证码失败"));
        }
      }))
    },
    onSendCode: (phone, code,captcha_id, captcha_answer) => {
      const data = {
        phone,
        code,
        captcha_id,
        captcha_answer,
      }
      dispatch(sendCode({
        data,
        success: () => {
          dispatch(messageOk('验证码已发送至您的手机！'));
        },
        error: (error) => {
            dispatch(messageError(error.msg));
            dispatch(renewCaptcha({
              success: (result) => {
              },
              error: (error) => {
                dispatch(messageError("获取图形验证码失败"));
              }
            }))
        }
      }))
    },
    onSubmit: (temp, args) => {
      let inviter = localStorage.getItem("inviter")
      let usermine_inviter = localStorage.getItem("usermine_inviter")
      
      let inviter_timestamp = parseInt(localStorage.getItem("inviter_timestamp"))
      // only 7 days valid
      if (inviter_timestamp !== 0 && inviter_timestamp > new Date().getTime()-7*86400000) {
        temp.inviter = inviter?inviter: undefined;
        temp.usermine_inviter = usermine_inviter?usermine_inviter: undefined;
      }

      temp.logintype = args.logintype;
      
      dispatch(signup({
        data: temp,
        success: (result) => {
          dispatch(messageOk('注册成功！'));
          // OAuth2
          if ((args.redirect_url || args.redirect_uri) && args.client_id && window.location.href.indexOf("https://third.pear.link") !== -1) {
            window.location.href = "https://third.pear.link/login" + window.location.search
            return
          } 
          let redirectURL = process.env.REACT_APP_SERVER + '/login'
          let query = encodeURIComponent('source=' + args.source + Object.entries(result).reduce((q, x) => `${q}&${x[0]}=${x[1]}`, ''))
          window.location.href = redirectURL + '?' + query
        },
        error: (error) => {
            dispatch(messageError(error.msg));
        }
      }))
    },
      messageOk: (data) => {
          dispatch(messageOk(data));
      },
      messageError: (data) => {
          dispatch(messageError(data));
      },
      messageWarning: (data) => {
          dispatch(messageWarning(data));
      }
    // onSubmit: (data, args) => {
    //   let temp = {}
    //   for (let k in data) {
    //     temp[k] = data[k].value
    //   }
    //   temp.logintype = args.logintype
    //   dispatch(signup({
    //     data: temp,
    //     success: (result) => {
    //       message.success('success')
    //       let redirectURL = process.env.REACT_APP_SERVER + '/login'
    //       let query = encodeURIComponent('source=' + args.source + Object.entries(result).reduce((q, x) => `${q}&${x[0]}=${x[1]}`, ''))
    //       window.location.href = redirectURL + '?' + query
    //     },
    //     error: (error) => {
    //       message.error(ERROR[error.errorcode])
    //     }
    //   }))
    // }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupBox)
