import xhr from '../Xhr'

const API = (data) => ({
  auth: {
    login: {
      url: process.env.REACT_APP_API_SERVER + '/v1/vdn/login',
      method: 'POST'
    },
    getTmpCode:{
      url: process.env.REACT_APP_OAUTH2_SERVER + '/code',
      method: 'POST'
    },
    wechatPublicLogin: {
      url: process.env.REACT_APP_WECHAT_SERVER + `/wechat_public/multiple/users/login?openid=${data}`,
      method: 'GET'
    },
    signup: {
      url: process.env.REACT_APP_API_SERVER + '/v1/vdn/signup',
      method: 'POST'
    },
    realnameInfo: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/${data}/real_name_auth`,
      method: 'GET'
    },
    magicBoxURL: {
        url: process.env.REACT_APP_API_MAGICBOX_SERVER + `/v1/magicbox/info`,
        method: 'GET'
    },
    realname: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/${data}/real_name_auth`,
      method: 'POST'
    },
    loginThird: {
      url: process.env.REACT_APP_API_SERVER + '/v1/vdn/thirdlogin/bind',
      method: 'POST'
    },
    bindWechatPublic: {
      url: process.env.REACT_APP_WECHAT_SERVER + '/wechat_public/bind"',
      method: 'POST'
    },
    forgetPwd: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/app/forget_password`,
      method: 'POST'
    },
    changePwd: {
        url: process.env.REACT_APP_API_SERVER + `/v1/vdn/1/password`,
        method: 'POST'
    },
    bind: {
      get:{
        url:process.env.REACT_APP_PEARBOX_SERVER + "/pearbox/binding",
        method:"GET",
      },
    },
  }
})

const codeAPI = (data={}) => ({
  renewCaptcha: {
    url: process.env.REACT_APP_API_SERVER + `/v1/vdn/captcha`,
    method: 'POST',
  },
  sendCode: {
    url: process.env.REACT_APP_API_SERVER + `/v1/vdn/vcode?mobile=${data.phone}&country_code=${data.code}&captcha_id=${data.captcha_id}&captcha_answer=${data.captcha_answer}`,
    method: 'GET'
  },
  forgrtSendCode: {
    url: process.env.REACT_APP_API_SERVER + `/v1/vdn/username/vcode?username=${data.username}&captcha_id=${data.captcha_id}&captcha_answer=${data.captcha_answer}`,
    method: 'GET'
  }
})

export const login = ({data, ...event}) => {
  return xhr({
    api: API().auth.login,
    action: 'AUTH/LOGIN',
    data,
    event
  })
}
export const getTmpCode = (data, event) => {
  return xhr({
    api: API().auth.getTmpCode,
    action: 'AUTH/LOGIN/TMP/CODE',
    data,
    event
  })
}

export const wechatPublicLogin = (data, event) => {
  return xhr({
    api: API(data).auth.wechatPublicLogin,
    action: 'AUTH/WECHAT_PUBLIC/MULTIPLE/USERS/LOGIN',
    event
  })
}

export const renewCaptcha = (event) => {
  return xhr({
    api: codeAPI().renewCaptcha,
    action: 'AUTH/RENEW_CAPTCHA',
    event
  })
}

export const setSendCodeDisabledTime = (data) => (
  {
    type: "SET/SEND/CODE/DISABLED/TIME",
    result: data
  }

)

export const sendCode = ({data, ...event}) => {
  return xhr({
    api: codeAPI(data).sendCode,
    action: 'AUTH/SEND/CODE',
    event
  })
}

export const forgetSendCode = ({data, ...event}) => {
  return xhr({
    api: codeAPI(data).forgrtSendCode,
    action: 'AUTH/SEND/CODE',
    event
  })
}

export const forgetPwdSubmit = ({data, ...event}) => {
  return xhr({
    api: API().auth.forgetPwd,
    action: 'FORGET/SUBMIT',
    data,
    event
  })
};

export const changePwdSubmit = ({data, ...event}) => {
    return xhr({
        api: API().auth.changePwd,
        action: 'PASSWORD/CHANGE',
        data,
        event
    })
}

export const signup = ({data, ...event}) => {
  return xhr({
    api: API().auth.signup,
    action: 'AUTH/SIGNUP',
    data,
    event
  })
}


export const submitRealname = ({data, ...event}) => {
  return xhr({
    api: API(data.userid).auth.realname,
    action: 'AUTH/REALNAME',
    token: data.token,
    data,
    event
  })
}

export const loginThird = ({data, ...event}) => {
  return xhr({
    api: API().auth.loginThird,
    action: 'AUTH/LOGIN',
    data,
    event
  })
}

export const bindWechatPublic = ({data, ...event}) => {
  return xhr({
    api: API().auth.bindWechatPublic,
    action: 'AUTH/WECHAT_PUBLIC/BIND',
    data,
    event
  })
}

export const getMagicBoxURL = ({...event}) => {
    return xhr({
        api: API().auth.magicBoxURL,
        action: 'AUTH/MAGIC',
        event
    })
}

export const loginQuery = (data) => ({
    type: 'AUTH/LOGIN',
    data
})

export const getBindInfo = (event)=>{
  return xhr({
      api:API().auth.bind.get,
      action:"GET/PEARBOX/BINDING",
      event,
  })
}